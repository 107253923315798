<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item>
            <el-input
              v-model="selectForm.Name"
              placeholder="职务级别名称"
            ></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <!--列表-->
    <el-table
      :data="users"
      highlight-current-row
      @current-change="selectCurrentRow"
      class="new_table"
      :header-cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column type="index"></el-table-column>
      <el-table-column
        label="职务级别名称"
        prop="Name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="职务级别"
        prop="Rank"
        align="center"
      ></el-table-column>
      <el-table-column
        label="职务描述"
        prop="Description"
        align="center"
      ></el-table-column>
      <el-table-column label="是否启用" prop="Enabled" align="center">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.Enabled ? 'success' : 'danger'"
            disable-transitions
          >
            {{ scope.row.Enabled ? "是" : "否" }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <!-- </div> -->
    <!--新增界面-->
    <el-dialog
      title="职务级别新增"
      :visible.sync="addFormVisible"
      v-model="addFormVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-form :model="addForm" :rules="addFormRules" ref="addForm">
        <el-form-item label="名称:" prop="Name">
          <el-col :span="20">
            <el-input v-model="addForm.Name"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="描述:" prop="Description">
          <el-col :span="20">
            <el-input v-model="addForm.Description"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="级别:" prop="Rank">
          <el-col :span="20">
            <el-input v-model="addForm.Rank" type="number" min="0"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="是否启用:" prop="Enabled">
          <el-switch v-model="addForm.Enabled"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button type="primary" @click="addSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!--编辑界面-->
    <el-dialog
      title="职务级别编辑"
      :visible.sync="editFormVisible"
      v-model="editFormVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-form :model="editForm" :rules="editFormRules" ref="editForm">
        <el-form-item label="名称:" prop="Name">
          <el-col :span="20">
            <el-input v-model="editForm.Name"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="描述:" prop="Description">
          <el-col :span="20">
            <el-input v-model="editForm.Description"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="级别:" prop="Rank">
          <el-col :span="20">
            <el-input v-model="editForm.Rank" type="number" min="0"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="是否启用:" prop="Enabled">
          <el-switch v-model="editForm.Enabled"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button type="primary" @click="editSubmit">提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import {
  GetPostTypes,
  AddPostRank,
  UpdatePostRank,
  DeletePostRank,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data() {
    return {
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 50, // 每页的数据条数
      page: 1,
      currentRow: null,
      buttonList: [],
      buttonListmsg: "postrank",
      selectForm: {
        Name: "",
      },
      users: [],
      addFormVisible: false, //新增界面是否显示
      editFormVisible: false, //编辑界面是否显示
      addFormRules: {
        Name: [
          { required: true, message: "请输入部门类型名称", trigger: "blur" },
        ],
      },
      editFormRules: {
        Name: [
          { required: true, message: "请输入部门类型名称", trigger: "blur" },
        ],
      },
      addForm: {
        //新增表单初始化
        Name: null,
        Description: null,
        Rank: null,
        Enabled: false,
      },
      editForm: {
        //编辑表单初始化
        Id: null,
        Name: null,
        Description: null,
        Rank: null,
        Enabled: false,
      },
    };
  },
  methods: {
    callFunction(item) {
      this.filters = {
        name: item.search,
      };
      this[item.Func].apply(this, item);
    },
    selectCurrentRow(val) {
      if (val != null) {
        this.currentRow = val;
      }
    },
    //获取部门类型
    getPostRank() {
      let para = {
        page: this.page,
        size: this.pageSize,
        name: this.selectForm.Name,
      };
      GetPostTypes(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    //新增表单显示
    handleAdd() {
      this.addFormVisible = true;
    },
    //新增表单提交
    addSubmit() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            let para = Object.assign({}, this.addForm);
            AddPostRank(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$refs["addForm"].resetFields();
                this.addFormVisible = false;
                this.getPostRank();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    //编辑页面显示
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });

        return;
      } else {
        this.editFormVisible = true;
        this.editForm = Object.assign({}, row);
      }
    },
    //编辑表单提交
    editSubmit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            let para = Object.assign({}, this.editForm);
            UpdatePostRank(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$refs["editForm"].resetFields();
                this.editFormVisible = false;
                this.currentRow = null;
                this.getPostRank();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    //删除所选数据
    handleDelete() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      } else {
        let para = { id: row.Id };
        DeletePostRank(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.currentRow = null;
            this.getPostRank();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      }
    },
  },
  mounted() {
    this.getPostRank();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}
</style>